<template>
  <div class="channels">
    <div class="text">
      <!-- <img
        src="../../assets/img/about/gushi/02.png"
        alt=""
      > -->
      <div class="textbox">
        <p class="ename">How to buy</p>
        <p class="nname">官方购买渠道</p>
        <p class="ahref">线上购买渠道</p>
        <div class="dpul">
          <ul>
            <li class="hdli"><a href="https://joocyee.tmall.com/?spm=a220o.1000855.1997427721.d4918089.23ab268dYaG3v9">天猫</a>
            </li>
            <li>拼多多
            </li>
          </ul>
          <ul>
            <li class="hdli"><a href="https://shop.m.jd.com/?shopId=10096324">京东</a>
            </li>
            <li>抖店
            </li>
          </ul>
          <ul>
            <li class="hdli"><a href="https://list.vip.com/brand.html?sn=10041799">唯品会</a>
            </li>
            <li>微信有赞店铺</li>
          </ul>
        </div>
        <p class="bhref">线下购买渠道</p>
        <p class="wow">WOW Colour</p>
        <p class="heat">HEAT</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.channels {
  width: 100%;
  height: calc(100vh - 7vh);
  background: url("../../assets/img/about/gushi/2.jpg") no-repeat center center;
  background-size: cover;
  .text {
    position: relative;
    z-index: 100;
    width: 24.375rem;
    height: 27.125rem;
    top: 50%;
    left: 78%;
    transform: translate(-50%, -50%);
    img {
      width: 24.375rem;
      height: 27.125rem;
    }
    .textbox {
      position: absolute;
      top: 0;
      left: 0.355rem;
    }
    .ename {
      font-size: 4.55rem;
      font-family: Arachne;
      line-height: 1.15;
    }
    .nname {
      font-size: 1.7rem;
      text-indent: 0.6rem;
      letter-spacing: 4px;
      margin-bottom: 3rem;
    }
    .ahref {
      display: inline-block;
      border-bottom: 1px solid #000;
      font-size: 1rem;
      margin-left: 0.5rem;
      margin-bottom: 1.8rem;
      line-height: 1;
    }
    .dpul {
      overflow: hidden;
      ul {
        float: left;
        font-size: 1rem;
        margin-left: 0.5rem;
        margin-bottom: 1.6rem;

        li {
          margin-bottom: 1.1rem;
        }
        .hdli {
          cursor: pointer;
        }
      }
      ul:nth-of-type(1),
      ul:nth-of-type(2) {
        margin-right: 4rem;
      }
    }
    .bhref {
      display: inline-block;
      border-bottom: 1px solid #000;
      font-size: 1rem;
      margin-left: 0.5rem;
      margin-bottom: 1.6rem;
      line-height: 1;
    }
    .wow {
      font-size: 1rem;
      text-indent: 0.6rem;
      margin-bottom: 1.25rem;
    }
    .heat {
      font-size: 1rem;
      text-indent: 0.6rem;
    }
  }
}
</style>